$(document).ready ->
  'use strict'

  # Navbar
  #
  $('.navbar').navbar()

  # Morph dropdown
  $('.navbar').morphDropdown()

  # Left Sidebar
  #
  left_sidebar = $('#sidebar')
  left_sidebar_menu = $('.sidebar-menu', left_sidebar)


  if left_sidebar.length > 0
    left_sidebar_menu.metisMenu()

    $('.nano-content', left_sidebar).perfectScrollbar()

    showing = false
    left_sidebar_menu.on 'show.metisMenu', =>
      left_sidebar.addClass '-open'
      if left_sidebar.hasClass '-open'
        showing = true
    left_sidebar_menu.on 'hidden.metisMenu', =>
      left_sidebar.removeClass '-open' unless showing
      showing = false

    # left_sidebar_menu.on 'shown.metisMenu hidden.metisMenu orientationchange', =>
    #   $('.nano', left_sidebar).nanoScroller()
    #   return

    # Click outside left sidebar
    close_left_sidebar = (e) =>
      if $(window).width() < 992 &&
         $(e.target).closest('#sidebar .sidebar-menu').length == 0
        $('.active a', left_sidebar).trigger 'click'
      return
    $(document).on 'click', close_left_sidebar

    # $(document).click (event) =>
    #   if not $(event.target).closest('#sidebar .sidebar-menu').length
    #     close_left_sidebar(event)
    #   return

  # Activate tooltips
  $('[data-toggle="tooltip"]').tooltip()

  # Activate popovers
  $('[data-toggle="popover"]').popover
    trigger: 'hover'

  # Content
  #
  resize_content_timeout = undefined
  resize_content = =>
    $('.content').css 'min-height': $(window).height() - $('.navbar').height()
    return
  $(window).resize =>
    resize_content_timeout = clearTimeout(resize_content_timeout)
    setTimeout(resize_content, 100)
    return
  resize_content()

  # Right Sidebar
  #
  right_sidebar = $('.sidebar.-slideable')

  if right_sidebar.length > 0
    right_sidebar_tabs = $('.sidebar .sidebar-tab')
    right_sidebar_tab_contents = $('.sidebar .sidebar-tab-content')
    right_sidebar_toggle = $('#right-sidebar-toggle')

    $('.nano-content', right_sidebar).perfectScrollbar()

    # Open and close sidebar
    right_sidebar_toggle.on 'click', (e) =>
      right_sidebar.toggleClass '-open'
      $('a', right_sidebar_toggle).toggleClass '-active'

      # setTimeout (=> $('.sidebar-wrapper', right_sidebar).nanoScroller()), 600
      return

    # Close sidebar on outside click
    close_right_sidebar = (e) =>
      if right_sidebar.hasClass('-open') &&
         $(e.target) != right_sidebar_toggle &&
         $(e.target).closest('#right-sidebar-toggle').length == 0 &&
         $(e.target).closest('.sidebar.-slideable').length == 0
        right_sidebar.removeClass '-open'
        $('a', right_sidebar_toggle).removeClass '-active'
      return
    $('body').on 'click', close_right_sidebar

    # Change sidebar tabs
    right_sidebar_tabs.each (index, tab) =>
      tab = $(tab)
      target = $(tab.attr('data-target'))

      tab.on 'click', (e) =>
        right_sidebar_tabs.removeClass '-active'
        right_sidebar_tab_contents.removeClass '-active'
        tab.addClass '-active'
        target.addClass '-active'

        # setTimeout (=> $('.sidebar-wrapper', right_sidebar).nanoScroller()), 600

        target.triggerHandler 'tab.show', [target]
        return
      return

    # Chat view
    chat_view_tab = $('#chat-view-tab')
    chat_wrapper = $('.chat')
    chat_items = $('.chat-items', chat_wrapper)
    chat_form = $('.chat-form', chat_wrapper)
    chat_user = $('.chat-user', chat_wrapper)
    chat_form_message = $('input[name="message"]', chat_wrapper)

    if chat_wrapper.length > 0
      # chat_view_tab.on 'mousewheel', (e) =>
      #   scrolled = chat_view_tab.parent().scrollTop()
      #   TweenLite.set chat_form,
      #     y: scrolled
      #   TweenLite.set chat_user,
      #     y: scrolled
      #   return

      chat_form.on 'submit', (e) =>
        message = chat_form_message.val()

        chat_form_message.val ''
        chat_items.append $('<div>', class: 'chat-item -to', text: message)

        # $('.nano', chat_view).nanoScroller()
        # $('.nano', chat_view)[0].nanoscroller.scrollBottom(0)

        e.preventDefault()
        return false


  # Wizards scroll
  #
  if $('.wizard .content').length > 0
    $('.wizard .content').perfectScrollbar()

  # Morph gallery scroll
  #
  if $('.morph-gallery').length > 0
    $('.morph-gallery').perfectScrollbar()

  return
